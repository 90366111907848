.fill-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.scroll {
  padding-right: 20px;
  margin-right: -10px;
  max-height: calc(100vh - 425px);
  min-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}