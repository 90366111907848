#progress {
  width: 100%;
  -webkit-appearance: none;
  height: 4px;
  background: linear-gradient(
    238deg,
    #39d0d8 7.75%,
    #36bce0 12.41%,
    #2e81f6 27.14%,
    #2b6aff 34.19%,
    #545cfb 41.55%,
    #8d48f6 52.86%,
    #b73af2 62.57%,
    #d131f0 70.18%,
    #da2eef 74.74%
  );
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

#progress:hover {
  cursor: pointer;
}

.bg-button {
  background: linear-gradient(90deg, #ba9666 0%, #fbeda1 100%);
  border-radius: 100px;
  border: 2px solid #fbeda1;
  width: 160px;
  height: 40px;
}

.bg-button-1 {
  background: #e4e4e4;
  border-radius: 100px;
  min-width: 200px;
  height: 40px;
}

.bg-button-2 {
  background: linear-gradient(90deg, #ba9666 0%, #fbeda1 100%) !important;
  border-radius: 100px;
  border: 2px solid #fbeda1;
  width: 160px;
  height: 40px;
}

.input-scale {
  transform: scale(1.4);
}

.form-check-input:checked {
  background-color: #00ccff;
  border-color: #0d6efd;
}
.processing {
  position: relative;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  margin-bottom: 20px;
}
.processing__bar {
  border-radius: 2px;
  background: linear-gradient(
    238deg,
    #39d0d8 7.75%,
    #36bce0 12.41%,
    #2e81f6 27.14%,
    #2b6aff 34.19%,
    #545cfb 41.55%,
    #8d48f6 52.86%,
    #b73af2 62.57%,
    #d131f0 70.18%,
    #da2eef 74.74%
  );
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.processing__dot {
  position: absolute;
  width: 12px;
  height: 12px;
  top: -4px;
  right: -6px;
  border-radius: 5px;
  background-color: #39d0d8;
}
.processing__value {
  position: absolute;
  color: #fff;
  top: 14px;
  right: 6px;
  font-weight: bold;
  text-align: center;
  transform: translateX(50%);
}

@media only screen and (max-width: 768px) {
  .m-full-width {
    width: 100%;
  }

  .m-mb-2 {
    margin-bottom: 8px;
  }
}
