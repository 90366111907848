.pointer {
  cursor: pointer;
}
.p-relative {
  position: relative;
}
.bg-header {
  background: radial-gradient(50% 50% at 50% 50%, #111641 0%, #181b33 100%);
}

.active {
  color: #00ccff !important;
}

.bg-main {
  background: radial-gradient(50% 50% at 50% 50%, #111641 0%, #0c0f27 100%);
  min-height: calc(100vh - 80px);
  overflow: hidden;
}

body {
  color: #ffffff;
  font-family: 'Montserrat';
  --bs-font-sans-serif: 'Montserrat', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
}

.img-logo {
  width: 248px;
  height: 38px;
  top: 24px;
  left: 165px;
}

.nav {
  height: 88px;
}

.d-col-button {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    63.85deg,
    #39d0d8 7.75%,
    #36bce0 12.41%,
    #2e81f6 27.14%,
    #2b6aff 34.19%,
    #545cfb 41.55%,
    #8d48f6 52.86%,
    #b73af2 62.57%,
    #d131f0 70.18%,
    #da2eef 74.74%
  );
  border-radius: 100px;
  height: 40px;
  flex-direction: row;
}

.custom-card {
  position: relative;
  padding: 1px;
  border-radius: 16px;
  box-shadow: 2px 1px 10px 2px rgb(19 27 130 / 70%);
  background: linear-gradient(
    63.85deg,
    #39d0d8 7.75%,
    #36bce0 12.41%,
    #2e81f6 27.14%,
    #2b6aff 34.19%,
    #545cfb 41.55%,
    #8d48f6 52.86%,
    #b73af2 62.57%,
    #d131f0 70.18%,
    #da2eef 74.74%
  );
}

.custom-card:after {
  border-radius: 16px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    63.85deg,
    #39d0d8 7.75%,
    #36bce0 12.41%,
    #2e81f6 27.14%,
    #2b6aff 34.19%,
    #545cfb 41.55%,
    #8d48f6 52.86%,
    #b73af2 62.57%,
    #d131f0 70.18%,
    #da2eef 74.74%
  );
  filter: blur(1px);
  transition-property: filter;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  transition-delay: 20ms;
}
.custom-card:hover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    63.85deg,
    #39d0d8 7.75%,
    #36bce0 12.41%,
    #2e81f6 27.14%,
    #2b6aff 34.19%,
    #545cfb 41.55%,
    #8d48f6 52.86%,
    #b73af2 62.57%,
    #d131f0 70.18%,
    #da2eef 74.74%
  );
  filter: blur(15px);
  transition-property: filter;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  transition-delay: 20ms;
}

.custom-card-content {
  z-index: 2;
  position: relative;
  padding: 32px 24px;
  background: #201e49;
  border-radius: 16px;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
  height: 8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #181b33;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 12px;
  border: 2px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
