.timeline {
  border-left: 3px solid #b565a7;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  padding-left: 24px;
  margin-left: 100px;
  list-style: none;
  text-align: left;
  width: calc(100% - 100px);
}

.timeline .event {
  border-bottom: 1px dashed rgb(131, 131, 131);
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  right: calc(100% + 48px);
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.8em;
  width: 80px;
}

.timeline .event:after {
  -webkit-box-shadow: 0 0 0 3px #b565a7;
  box-shadow: 0 0 0 3px #b565a7;
  left: -30px;
  background: #fff;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  content: '';
  top: 5px;
}

.scroll {
  margin-right: -10px;
  padding-right: 20px;
  max-height: calc(100vh - 297px);
  min-height: calc(100vh - 297px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.bg-button-2 {
  background: linear-gradient(90deg, #ba9666 0%, #fbeda1 100%) !important;
  border-radius: 100px;
  border: 2px solid #fbeda1;
  width: 160px;
  height: 40px;
}
